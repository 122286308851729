import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import history from 'helpers/history';
import { productPropTypes } from 'helpers/propTypes';
import { getFullUrl } from 'helpers/window';
import { sendSuccessNotification } from 'helpers/notifications';
import { updateCart } from 'redux/actions/cart.actions';
import { itemQuantitySelector, itemsQuantitySelector } from 'redux/selectors/cart.selector';
import { productImagesSelector } from 'redux/selectors/product.selector';

import Product from 'views/Product';

const ProductContainer = (props) => {
  const {
    cartItem, images, item, onClose, updateCartAction, cartItemDescriptions,
  } = props;
  const quantity = cartItem ? cartItem.quantity : 0;
  const singleProductUrl = `/producto/${item.code.replaceAll('%', '')}`;
  const singleProductFullUrl = getFullUrl(singleProductUrl);
  const [selectedColor, setSelectedColor] = useState(null);
  const [imagesColor, setImagesColor] = useState([]);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const imagesDescription = [];
    if (color.image_1) {
      imagesDescription.push(color.image_1);
    } else {
      imagesDescription.push(images[0]);
    }
    if (color.image_2) imagesDescription.push(color.image_2);
    setImagesColor(imagesDescription);
  };

  const colors = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
  );
  const talles = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 2,
  );
  const combinations = colors?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combination = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combination) a[b.stock_description_id].push(combination);
    return a;
  }, Object.create(null));

  const combinationsTalle = talles?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combinationTalle = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 2)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combinationTalle) a[b.stock_description_id].push(combinationTalle);
    return a;
  }, Object.create(null));

  const onProductClick = () => {
    onClose();
    history.push(singleProductUrl);
  };

  const onProductCopyClick = () => {
    sendSuccessNotification('Link del producto copiado con éxito');
  };

  const onQuantityChange = (value, description) => {
    if (description) {
      if (value >= 0 && value <= description.stock) {
        updateCartAction(item.id, value, description.id);
      }
    } else if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null);
    }
  };

  const getValidPriceRange = () => {
    const validPrices = talles.filter((talle) => talle.price !== null && talle.price > 0).map((talle) => talle.price);
    const distinctPrices = Array.from(new Set(validPrices));
    if (distinctPrices.length === 1) {
      return `$${validPrices[0].toString()}`;
    }
    const minPrice = Math.min(...distinctPrices);
    const maxPrice = Math.max(...distinctPrices);
    return `$${minPrice} - $${maxPrice}`;
  };

  return (
    <Product
      images={selectedColor ? imagesColor : images}
      selectedColor={selectedColor}
      handleColorSelect={handleColorSelect}
      item={item}
      onProductClick={onProductClick}
      onProductCopyClick={onProductCopyClick}
      onQuantityChange={onQuantityChange}
      quantity={quantity}
      cartItemDescriptions={cartItemDescriptions}
      singleProductFullUrl={singleProductFullUrl}
      colors={colors}
      talles={talles}
      combinations={combinations}
      combinationsTalle={combinationsTalle}
      closeModal={onClose}
      getValidPriceRange={getValidPriceRange}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
  images: productImagesSelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ProductContainer.defaultProps = {
  cartItem: null,
  cartItemDescriptions: null,
  onClose: () => { },
};

ProductContainer.propTypes = {
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  item: productPropTypes.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
