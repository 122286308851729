import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { productPropTypes } from 'helpers/propTypes';
import { itemQuantitySelector } from 'redux/selectors/cart.selector';
import { openProductModal } from 'redux/actions/modal.actions';
import { updateCart } from 'redux/actions/cart.actions';

import Item from 'components/commons/Item';

const ItemContainer = (props) => {
  const {
    cartItem, item, openProductModalAction, updateCartAction,
  } = props;

  const quantity = cartItem ? cartItem.quantity : 0;
  const colors = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
  );
  const talles = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 2,
  );

  const combinations = colors?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combination = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combination) a[b.stock_description_id].push(combination);
    return a;
  }, Object.create(null));

  const combinationsTalle = talles?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combinationTalle = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 2)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combinationTalle) a[b.stock_description_id].push(combinationTalle);
    return a;
  }, Object.create(null));
  const onQuantityChange = (value) => {
    if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null);
    }
  };

  const getValidPriceRange = () => {
    const validPrices = talles.filter((talle) => talle.price !== null && talle.price > 0).map((talle) => talle.price);
    const distinctPrices = Array.from(new Set(validPrices));
    if (distinctPrices.length === 1) {
      return `$${validPrices[0].toString()}`;
    }
    const minPrice = Math.min(...distinctPrices);
    const maxPrice = Math.max(...distinctPrices);
    return `$${minPrice} - $${maxPrice}`;
  };
  return (
    <Item
      item={item}
      onItemClick={() => openProductModalAction(item)}
      onQuantityChange={onQuantityChange}
      quantity={quantity}
      combinations={combinations}
      combinationsTalle={combinationsTalle}
      colors={colors}
      talles={talles}
      getValidPriceRange={getValidPriceRange}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ItemContainer.defaultProps = {
  cartItem: null,
};

ItemContainer.propTypes = {
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  item: productPropTypes.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);
