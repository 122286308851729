import React, { useState, useEffect, useRef } from 'react';
import ItemsCarousel from 'react-items-carousel';
import PropTypes from 'prop-types';
import { productsArrayPropTypes } from 'helpers/propTypes';
import ItemContainer from 'containers/ItemContainer';

const CarrouselProducts = (props) => {
  const { products } = props;
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const productsRef = useRef([]);
  const activeItemIndexRef = useRef(0);
  const intervalRef = useRef(0);

  const responsiveCarrousel = () => {
    const width = window.innerWidth;
    if (width >= 1000) {
      return 4;
    } if (width >= 768) {
      return 4;
    } if (width >= 550) {
      return 3;
    } if (width >= 200) {
      return 2;
    }
    return 1;
  };

  const tick = () => {
    const index = (activeItemIndexRef.current || 0) + 1;
    setActiveItemIndex(index % (productsRef.current.length - responsiveCarrousel() + 1));
  };

  const stopCarrousel = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = 0;
    }
  };

  const startCarrousel = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(tick, 2000);
    }
  };

  useEffect(() => {
    startCarrousel();
    return stopCarrousel;
  }, []);

  useEffect(() => {
    productsRef.current = products;
  }, [products]);

  useEffect(() => {
    activeItemIndexRef.current = activeItemIndex;
  }, [activeItemIndex]);

  const renderItemCarousel = (item) => (
    <div className="col-product-carrousel" id={`item-${item.id}`} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );

  return (
    <div
      className="carrousel-wrapper"
      onMouseEnter={stopCarrousel}
      onMouseLeave={startCarrousel}
    >
      <ItemsCarousel
        infiniteLoop
        gutter={20}
        activePosition="center"
        chevronWidth={60}
        chevronHeight={30}
        numberOfCards={responsiveCarrousel()}
        outsideChevron
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        rightChevron={(
          <div className="button-carrousel">
            {'>'}
          </div>
        )}
        leftChevron={(
          <div className="button-carrousel">
            {'<'}
          </div>
        )}
      >
        {products.map((item) => renderItemCarousel(item))}
      </ItemsCarousel>
    </div>
  );
};

CarrouselProducts.propTypes = {
  products: productsArrayPropTypes.isRequired,
  active: PropTypes.string.isRequired,
};

export default CarrouselProducts;
