import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { productPropTypes } from 'helpers/propTypes';

import QuantitySelector from 'components/commons/QuantitySelector';

import './Item.scss';

const Item = (props) => {
  const {
    item, onItemClick, onQuantityChange, quantity, colors, combinations, combinationsTalle, talles, getValidPriceRange
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [tallesXColor, setTallesXColor] = useState(null);

  const handleColorSelect = (color, descriptions) => {
    setSelectedColor(color);
    setTallesXColor(descriptions);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const otherImage = item.image2 ? item.image2 : item.image1;
  const selectedColorImage =  selectedColor ? (selectedColor?.image_2 ? selectedColor.image_2: selectedColor.image_1) : item.image1;
  const imageSource = selectedColor ? (isHovered ? selectedColor.image_1 : selectedColorImage) : (isHovered ? otherImage : item.image1);

  const dots = (word) => (word.length > 20 ? `${word.substring(0, 20)}...` : word);
  const renderProductButton = () => {
    if (item.stock_product_descriptions.length > 0) {
      return (
        <div className="product-quantity-selector">
          <button type="button" onClick={onItemClick}>Ver colores</button>
        </div>
      );
    }

    if (item.stock > 0) {
      return (
        <div className="product-quantity-selector">
          <QuantitySelector
            limit={item.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
          />
        </div>
      );
    }

    return <span className="product-out-of-stock">Sin stock</span>;
  };
  const hasNonNullPrice = talles.some(talle => talle.price !== null && talle?.price > 0);


  return (
    <div className="card">
      <div>
        <img
          onClick={onItemClick}
          alt={item.name}
          src={imageSource}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          role="presentation"
        />
        <div onClick={onItemClick} className="buy-div">
          <span>COMPRAR</span>
        </div>
      </div>
      <div className="card-info">
        {item.sale > 0
        && (
        <div className="discount-hight-light">
          <p className="flag discount-percent">{`- ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</p>
        </div>
        )}
        <h2>{dots(item.name)}</h2>
        <div className="price-container">
          { !hasNonNullPrice ? (
            <>
            {item.sale > 0
              ? (
                <>
                  <span className="price">
                    {`$${item.sale}`}
                  </span>
                  <span className="discount">{`$${item.price}`}</span>
                </>
              )
              : <span className="price">{`$${item.price}`}</span>}
            </>
          ) : (
            <span className="price">{getValidPriceRange()}</span>
          ) }

        </div>
        {Object.values(combinations).some(array => array.length > 0) && (
          <div className="color-selector">
            {Object.keys(combinations)?.map((id) => {
              const color = colors.find(({ stock_description_id }) => stock_description_id === Number(id));
              const descriptions = combinations[id];
              const isMulticolor = color.stock_descriptions.name === "Multicolor";
              return (
                <div
                  key={id}
                  className={`color-circle ${isMulticolor ? 'multicolor' : ''} ${selectedColor?.id === color.id ? 'selected' : ''}`}
                  style={isMulticolor ? {} : { backgroundColor: color.stock_descriptions.rgb }}
                  onClick={() => handleColorSelect(color, descriptions)}
                />
              );
            })}
          </div>
        )}
        
        <div className="color-selector">
          { talles.length > 0 && (
          <>
            Talles:&nbsp;
          </>
          )}
          { !tallesXColor ? (Object.keys(combinationsTalle)?.map((id) => {
            const talle = talles.find(({ stock_description_id }) => stock_description_id === Number(id));
            return (
              <span>{talle.stock_descriptions.name}</span>
            );
          })) : (
            tallesXColor.map((talle) =>               
              <span>{talle.stock_descriptions.name}</span>
            )
          )}
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  item: productPropTypes.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Item;
