import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import history from 'helpers/history';
import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import CarrouselProducts from 'components/commons/CarrouselProducts';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.scss';

const Home = (props) => {
  const {
    items, categoriesHome, sliders, offers, newArrivals
  } = props;

  const onCategoryClick = (code) => {
    history.push(`/productos/${code}`);
  };
  const renderSilder = () => (
    <div data-testid="slider">
      <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        swipeable={false}
      >
        {sliders.map(({ id, image, text }) => (
          <div className="main-slider" key={id}>
            <img className="main-slider-img" src={image} alt={text} />
          </div>
        ))}
      </Carousel>
    </div>
  );

  return (
    <div data-testid="home-page">
      {!!sliders.length && renderSilder()}
      <div className="row align-items-center">
                  <div className="col text-center resp-carrousel">
                    { categoriesHome?.length > 0
                    && (
                    <div className="shop-by-category">
                      <div className="categories">
                        {
                          categoriesHome?.map((item) => (
                            <div key={item.id} className="category" onClick={() => onCategoryClick(item.category.code)} role="presentation">
                              <img alt="Abrigo" src={item.category.image} />
                              <div className={`title-category${item.category.name.length > 10 ? "-large" : "" }`}>
                                <h4>{item.category.name}</h4>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    )}
                  </div>
                </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section">
              <div className="section home-products-carrousel">
              </div>
            </div>
            <div className="section">
              <div className="home-look">
                <h4 className="title2-home"> NUEVOS INGRESOS </h4>
              </div>
              <div className="row align-items-center">
                <div className="col text-center resp-carrousel">
                  <CarrouselProducts
                    products={newArrivals}
                  />
                </div>
              </div>
            </div>
            {offers?.length > 0 &&
            <div className="section">
              <div className="home-look">
                <h4 className="title2-home"> Ofertas </h4>
              </div>
              <div className="row align-items-center">
                <div className="col text-center resp-carrousel">
                  <CarrouselProducts
                    products={offers}
                  />
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  currentSection: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  items: productsArrayPropTypes.isRequired,
  newArrivals: productsArrayPropTypes.isRequired,
  offers: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
};

export default Home;
