import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Zoom from 'react-medium-image-zoom';

import { productPropTypes } from 'helpers/propTypes';
import { getCookie, setCookie } from 'helpers/cookies';
import { setVisitsProducts } from 'services/user.services';

import Button from 'components/commons/Button';
import DescriptionItem from 'components/commons/DescriptionItem';
import QuantitySelector from 'components/commons/QuantitySelector';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-medium-image-zoom/dist/styles.css';
import './Product.scss';
import { Table } from 'react-bootstrap';

const Product = (props) => {
  const {
    images, item, onProductClick, onProductCopyClick, onQuantityChange, quantity,
    singleProductFullUrl, cartItemDescriptions, colors, combinations, talles, combinationsTalle,
    selectedColor, handleColorSelect, closeModal, getValidPriceRange
  } = props;

  useEffect(() => {
    updateCookie();
  });

  const updateCookie = async () => {
    try {
      if (getCookie('visit_product')) {
        const arrCount = getCookie('visit_product');
        addProduct(arrCount);
      } else {
        const arrCount = [];
        addProduct(arrCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hasNonNullPrice = talles.some(talle => talle.price !== null && talle?.price > 0);

  const addProduct = async (arrCount) => {
    const existProduct = arrCount.includes(item.id);
    if (!existProduct) {
      arrCount.push(item.id);
      setCookie('visit_product', arrCount, { maxAge: 86400 });
      const data = {
        id: item.id,
      };
      await setVisitsProducts(data);
    }
  };

  const renderProductQuantitySelector = () => {
    if (item.stock_product_descriptions.length > 0) {
      return (
        <div class="table-responsive">
        <Table  responsive className="colors-table">
          <thead>
            <tr>
              <th className="th-1">Color/ Talle</th>
              { Object.values(combinations).some(array => array.length > 0) &&
                <>
                {Object.keys(combinationsTalle)?.map((id) => {
                  const talle = talles.find(({ stock_description_id }) => stock_description_id === Number(id));
                  if (Object.keys(talle).length > 0) {
                    return (
                      <>
                        <th className="th-2">
                          <span>{talle.stock_descriptions.name}</span>
                        </th>
                      </>
                    );
                  }
                  return (
                    <th className="th-2">
                      <span>Sin talle</span>
                    </th>
                  );
                })}
                </>
              }
              {!Object.values(combinations).some(array => array.length > 0) && colors.length > 0 && (
                <th className="th-2">
                  <span>Unico</span>
                </th>
              )}
              {!Object.values(combinations).some(array => array.length > 0) && talles.length > 0 && (
                <th className="th-2">
                  <span>Unico</span>
                </th>
              )}
            </tr>
            <tr>
              
              { (hasNonNullPrice && Object.values(combinations).some(array => array.length > 0)) &&
                <>
                  <> 
                    <th className="th-1">
                      <span>Precio</span>
                    </th>
                  </>
                {(Object.keys(combinationsTalle)?.map((id) => {
                    const talle = talles.find(({ stock_description_id }) => stock_description_id === Number(id));
                    if (Object.keys(talle).length > 0 && talle?.price !== null && talle?.price > 0) {
                      return (
                        <>
                          <th className="th-2">
                            <span>${talle.price}</span>
                          </th>
                        </>
                      );
                    }
                    return (
                      <th className="th-2">
                        <span>-</span>
                      </th>
                    );
                  })
                  )}
                </>
              }
            </tr>
          </thead>
          <tbody>
            {Object.values(combinations).some(array => array.length > 0) && (
            <>
            {Object.keys(combinations)?.map((colorId) => {
              const color = colors.find(({ stock_description_id }) => stock_description_id === Number(colorId));
              const descriptions = combinations[colorId];
              const isMulticolor = color.stock_descriptions.name === "Multicolor";

              return (
                <tr key={`${item.id}-${colorId}`}>
                  <td className="color__item" data-color-id="13" data-color-name="Negro">
                    <div className="color-container">
                      <span 
                        className={`color-circle ${isMulticolor ? 'multicolor' : ''} ${selectedColor?.id === color.id ? 'selected' : ''}`}
                        onClick={() => handleColorSelect(color)}
                        style={isMulticolor ? {} : { backgroundColor: color.stock_descriptions.rgb }}
                        />
                    </div>
                    <span className="color-name">{color.stock_descriptions.name}</span>
                  </td>
                  {Object.keys(combinationsTalle)?.map((id) => {
                    const description = descriptions.find(({ stock_description_id }) => stock_description_id === Number(id));
                    return (
                      <td className="td-2" >
                        <li className="float-xs-left">
                          {description && description.stock > 0 && (
                          <DescriptionItem
                            description={description}
                            onQuantityChange={onQuantityChange}
                            storage={cartItemDescriptions.find((data) => data.descriptionId === description.id)}
                          />
                          )}
                        </li>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            </>
            )}
            {!Object.values(combinations).some(array => array.length > 0) && colors?.length > 0 && (
              <>
              {colors.map((color) => (
                <tr key={`${item.id}-${color.id}-${item.name}`}>
                  <td className="color__item" data-color-id="13" data-color-name="Negro">
                    <div className="color-container">
                      <span className="color-circle" style={{ backgroundColor: color.stock_descriptions.rgb }} />
                      <span className="color-name">{color.stock_descriptions.name}</span>
                    </div>
                  </td>
                  <td className="td-2" key={`${item.id}-${color.id}`}>
                    <li className="float-xs-left">
                      <DescriptionItem
                        description={color}
                        onQuantityChange={onQuantityChange}
                        showQuantitySelector
                        storage={cartItemDescriptions.find((data) => (data.descriptionId === color.id))}
                      />
                    </li>
                  </td>
                </tr>
              ))}
              </>
            )}
            {!Object.values(combinations).some(array => array.length > 0) && talles.length > 0 && (
              <>
                {talles.map((talle) => (
                  <tr key={`${item.id}-${talle.id}-${item.name}`}>
                    <td className="color__item" data-color-id="13" data-color-name="Negro">
                      <div className="color-container">
                        <span className="color-name">{talle.stock_descriptions.name}</span>
                        {(talle?.price > 0) && (
                          <span className="color-name">- ${talle.price}</span>
                        )}
                      </div>
                    </td>
                    <td className="td-2" key={`${item.id}-${talle.id}`}>
                      <li className="float-xs-left">
                        <DescriptionItem
                          description={talle}
                          onQuantityChange={onQuantityChange}
                          showQuantitySelector
                          storage={cartItemDescriptions.find((data) => (data.descriptionId === talle.id))}
                        />
                      </li>
                    </td>
                  </tr>
                ))}
              </>
            )}            
          </tbody>
        </Table>
        </div>
      );
    }
    if (item.stock > 0) {
      return (
        <QuantitySelector
          limit={item.stock}
          onQuantityChange={onQuantityChange}
          quantity={quantity}
        />
      );
    }

    return <span className="product-details-out-of-stock">Sin stock</span>;
  };

  return (
    <div data-testid="product-modal">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="product-details-image-container">
              <Carousel
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showThumbs
                swipeable={false}
              >
                {images.map((image, index) => (
                  <Zoom key={`${image.id}-${index}`}>
                    <img alt={item.name} src={image} width="250" />
                  </Zoom>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="product-details">
              <span
                className="product-details-name"
                onClick={onProductClick}
                role="presentation"
              >
                {item.name}
              </span>
              <CopyToClipboard
                text={singleProductFullUrl}
                onCopy={onProductCopyClick}
              >
                <i
                  className="fa fa-clone product-details-copy-icon"
                  aria-hidden="true"
                  title="Copiar link del producto"
                />
              </CopyToClipboard>
              {item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
              <div className="product-details-section">
                <div className="product-details-price">
                  { !hasNonNullPrice ? (
                    <>
                      {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                      {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                    </>
                  ) : (
                    <>
                    {getValidPriceRange()}
                    </>
                  )
                  }
                </div>
              </div>
              <div className="separator" />
              <div className="product-details-section">
                {renderProductQuantitySelector()}
              </div>
              <div className="product-details-section">
                <span className="product-details-description"><pre>{item.description}</pre></span>
              </div>
              <div className="product-details-section">
                <Button text="Seguir comprando" onClick={closeModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  cartItemDescriptions: PropTypes.array.isRequired,
  item: productPropTypes.isRequired,
  onProductClick: PropTypes.func.isRequired,
  onProductCopyClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  singleProductFullUrl: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Product;
