export const APP_INITIALIZE_REQUESTED = 'APP_INITIALIZE_REQUESTED';

export const APP_DISCOUNT_REQUESTED = 'APP_DISCOUNT_REQUESTED';
export const APP_DISCOUNT_SUCCEEDED = 'APP_DISCOUNT_SUCCEEDED';
export const APP_DISCOUNT_FAILED = 'APP_DISCOUNT_FAILED';

export const APP_MINIMUM_PURCHASE_REQUESTED = 'APP_MINIMUM_PURCHASE_REQUESTED';
export const APP_MINIMUM_PURCHASE_SUCCEEDED = 'APP_MINIMUM_PURCHASE_SUCCEEDED';
export const APP_MINIMUM_PURCHASE_FAILED = 'APP_MINIMUM_PURCHASE_FAILED';

export const APP_SLIDERS_GET_ALL_REQUESTED = 'APP_SLIDERS_GET_ALL_REQUESTED';
export const APP_SLIDERS_GET_ALL_SUCCEEDED = 'APP_SLIDERS_GET_ALL_SUCCEEDED';
export const APP_SLIDERS_GET_ALL_FAILED = 'APP_SLIDERS_GET_ALL_FAILED';

export const APP_WHATSAPP_REQUESTED = 'APP_WHATSAPP_REQUESTED';
export const APP_WHATSAPP_SUCCEEDED = 'APP_WHATSAPP_SUCCEEDED';
export const APP_WHATSAPP_FAILED = 'APP_WHATSAPP_FAILED';

export const APP_CATALOGS_REQUESTED = 'APP_CATALOGS_REQUESTED';
export const APP_CATALOGS_SUCCEEDED = 'APP_CATALOGS_SUCCEEDED';
export const APP_CATALOGS_FAILED = 'APP_CATALOGS_FAILED';

export const APP_PRICES_REQUESTED = 'APP_PRICES_REQUESTED';
export const APP_PRICES_SUCCEEDED = 'APP_PRICES_SUCCEEDED';
export const APP_PRICES_FAILED = 'APP_PRICES_FAILED';

export const APP_MP_BUTTON_REQUESTED = 'APP_MP_BUTTON_REQUESTED';
export const APP_MP_BUTTON_SUCCEEDED = 'APP_MP_BUTTON_SUCCEEDED';
export const APP_MP_BUTTON_FAILED = 'APP_MP_BUTTON_FAILED';
