import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import { getLocalStorage } from 'helpers/storage';

import Breadcrumb from 'components/commons/Breadcrumb';
import DescriptionItem from 'components/commons/DescriptionItem';
import QuantitySelector from 'components/commons/QuantitySelector';
import Button from 'components/commons/Button';
import Benefit from 'components/commons/Benefit';

import './Cart.scss';

const Cart = (props) => {
  const {
    cartSize, items, minimumPurchase, onQuantityChange, onRemoveFromCart, subtotal,
    priceDiscount, onItemClick,
  } = props;
  const showCart = cartSize > 0;

  const renderEmptyCart = () => (
    <h2 className="text-center" data-testid="empty-cart">
      El carrito está vacío
    </h2>
  );

  const renderPrice = (item) => {
    let price = false;
    if (item.stock_product_descriptions) {
      price = item.stock_product_descriptions.some((stock_product_description) => stock_product_description.price !== null && stock_product_description.price > 0);
    }
    return price;
  };

  const renderPriceDescriptions = (talles) => {
    const uniqueArray = talles.reduce((accumulator, currentObj) => {
      const existingObj = accumulator.find((obj) => obj.stock_description_id === currentObj.stock_description_id);
      if (!existingObj) {
        accumulator.push(currentObj);
      }
      return accumulator;
    }, []);
    return (
      <div className="precios-talles">
        {uniqueArray.map((item, index) => (
          <>
            {item.stock_descriptions.name}
            : $
            {item.price}
            {index < uniqueArray.length - 1 ? ', ' : ''}
          </>
        ))}
      </div>
    );
  };
  const renderFullCart = () => (
    <div className="cart" data-testid="full-cart">
      <h2 className="text-center">
        {`Hay ${cartSize} producto${cartSize > 1 ? 's' : ''}`}
      </h2>
      <div className="cart-items">
        <div className="separator" />
        {items.map(({
          item, quantity, colors, talles, descriptions,
        }) => !!item && (
          <div className="cart-item" key={`cart-item-${item.id}`}>
            <div className="d-flex">
              <Button
                text={<i className="fa fa-remove" />}
                buttonClass="cart-item-remove"
                onClick={() => onRemoveFromCart(item)}
              />
            </div>
            <div className="row">
              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <img className="cursor-pointer" onClick={() => onItemClick(item)} src={item.image1} alt={item.name} role="presentation" />
              </div>
              <div className="col-md-8">
                <ul className="cart-item-info">
                  <li className="d-flex flex-row">
                    <span className="cart-item-title cursor-pointer" onClick={() => onItemClick(item)} role="presentation">
                      {item.name}
                    </span>
                  </li>
                  <li>
                    <span className="cart-item-description">
                      {item.description}
                    </span>
                  </li>
                  <li>
                    <span className="cart-item-price">
                      {
                        renderPrice(item) ? (
                          <>
                            Precio por talle
                            {renderPriceDescriptions(talles)}
                          </>
                        ) : (
                          <>
                            {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                            {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                          </>
                        )
                      }
                    </span>
                  </li>
                  <li className="descriptions-col">
                    {colors?.length > 0 && descriptions && (
                      Object.keys(descriptions).map((id) => {
                        const color = colors.find(
                          ({ stock_description_id }) => stock_description_id === Number(id),
                        );
                        const combinations = descriptions[id];
                        return !!combinations.length && (
                          <div className="cart-item-description-option" key={`${item.id}-${id}`}>
                            {combinations.map((combination, index) => (
                              <Fragment key={`${item.id}-${combination.description.id}`}>
                                {index === 0 && (
                                  <div className="clearfix">
                                    <DescriptionItem
                                      description={color}
                                      onQuantityChange={(value, desc) => {
                                        onQuantityChange(item, value, desc);
                                      }}
                                      showQuantitySelector={combination.description.id === color.id}
                                      storage={{ quantity: combination.quantity }}
                                      cart
                                    />
                                  </div>
                                )}
                                {combination.description.id !== color.id && (
                                  <div className="float-xs-left-cart" key={`${item.id}-${combination.description.id}`}>
                                    <DescriptionItem
                                      description={combination.description}
                                      onQuantityChange={(value, desc) => {
                                        onQuantityChange(item, value, desc);
                                      }}
                                      storage={{ quantity: combination.quantity }}
                                      cart
                                    />
                                  </div>
                                )}
                              </Fragment>
                            ))}
                          </div>
                        );
                      })
                    )}
                    {talles?.length > 0 && colors?.length === 0 && descriptions && (
                      Object.keys(descriptions).map((id) => {
                        const talle = talles.find(
                          ({ stock_description_id }) => stock_description_id === Number(id),
                        );
                        const combinations = descriptions[id];
                        return !!combinations.length && (
                        <div className="cart-item-description-option" key={`${item.id}-${id}`}>
                          {combinations.map((combination, index) => (
                            <Fragment key={`${item.id}-${combination.description.id}`}>
                              {index === 0 && (
                              <div className="clearfix">
                                <DescriptionItem
                                  description={talle}
                                  onQuantityChange={(value, desc) => {
                                    onQuantityChange(item, value, desc);
                                  }}
                                  showQuantitySelector={combination.description.id === talle.id}
                                  storage={{ quantity: combination.quantity }}
                                  cart
                                />
                              </div>
                              )}
                            </Fragment>
                          ))}
                        </div>
                        );
                      })
                    )}
                    { !colors && !talles && (
                      <QuantitySelector
                        limit={item.stock}
                        onQuantityChange={(value) => onQuantityChange(item, value, null)}
                        quantity={quantity}
                      />
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="separator" />
          </div>
        ))}
      </div>
    </div>
  );

  const renderCheckoutDetails = () => (
    <div className="row" data-testid="checkout-details">
      <div className="col">
        <div className="checkout">
          <ul className="checkout-details">
            <li>
              Subtotal:
              {priceDiscount === null ? (
                <span className="float-right">
                  {`$${subtotal.toLocaleString('de-DE')}`}
                </span>
              ) : (
                <del className="float-right">
                  {`$${subtotal.toLocaleString('de-DE')}`}
                </del>
              )}
            </li>
            {priceDiscount != null ? (
              <li>
                Con descuento
                {' '}
                {getLocalStorage('percentage')}
                %:
                <span className="float-right">
                  {`$${priceDiscount.toLocaleString('de-DE')}`}
                </span>
              </li>
            ) : ''}
          </ul>
          <div className="row">
            <div className="col-lg-6">
              <Button text="Envío a domicilio" buttonClass="checkout-button" href="/envio-a-domicilio" />
            </div>
            <div className="col-lg-6">
              <Button text="Retiro en Sucursal SandyProietti" buttonClass="checkout-button" href="/retiro-en-sucursal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="page-cart">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/carrito']} names={['Carrito']} />
            <div className="row">
              <div className="col">
                {!showCart && renderEmptyCart()}
                {showCart && renderFullCart()}
                {showCart && renderCheckoutDetails()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Cart.defaultProps = {
  priceDiscount: null,
};

Cart.propTypes = {
  cartSize: PropTypes.number.isRequired,
  items: cartItemsArrayPropTypes.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  subtotal: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
  priceDiscount: PropTypes.number,
};

export default Cart;
