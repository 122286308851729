import React from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/icons/List';

import './CartSummary.scss';
import { getLocalStorage } from 'helpers/storage';

const CartSummary = (props) => {
  const {
    items, shippingText, subtotal, coupon,
  } = props;

  const discount = getLocalStorage('discount');
  const subtotalValue = parseFloat(subtotal);
  const percentageOrAmount = coupon?.percentage ? (coupon.percentage / 100) * subtotal : (coupon?.amount || 0);
  const totalValueWithDiscount = (discount - percentageOrAmount) > 0 ? discount - percentageOrAmount : 0;
  const totalValueWithoutDiscount = (subtotal - percentageOrAmount) > 0 ? subtotal - percentageOrAmount : 0;
  const totalValueWithPercetage = subtotal - percentageOrAmount;

  const renderPrice = (item) => {
    let price = false;
    if (item.stock_product_descriptions) {
      price = item.stock_product_descriptions.some((stock_product_description) => stock_product_description.price !== null && stock_product_description.price > 0);
    }
    return price;
  };

  const renderPriceDescriptions = (talles) => {
    const uniqueArray = talles.reduce((accumulator, currentObj) => {
      const existingObj = accumulator.find((obj) => obj.stock_description_id === currentObj.stock_description_id);
      if (!existingObj) {
        accumulator.push(currentObj);
      }
      return accumulator;
    }, []);
    return (
      <div className="precios-talles">
        {uniqueArray.map((item, index) => (
          <>
            {item.stock_descriptions.name}
            : $
            {item.price}
            {index < uniqueArray.length - 1 ? ', ' : ''}
          </>
        ))}
      </div>
    );
  };
  return (
    <div className="container" data-testid="cart-summary-component">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <List />
          </Avatar>
          <h4>Resumen de la compra</h4>
          <div className="summary-items">
            {items.map(({
              item, quantity, colors, talles, descriptions,
            }) => !!item && (
              <div key={item.id}>
                <div className="d-flex flex-row">
                  <div className="summary-item-image">
                    <img src={item.image1} alt={item.name} />
                  </div>
                  <div className="summary-item-info">
                    <ul>
                      <li className="d-flex flex-row">
                        <span className="summary-item-title">
                          {item.name}
                        </span>
                      </li>
                      <li>
                        <span className="summary-item-description">
                          {item.description}
                        </span>
                      </li>
                      {colors?.length > 0 && descriptions && (
                        Object.keys(descriptions).map((id) => {
                          const color = colors.find(
                            ({ stock_description_id }) => stock_description_id === Number(id),
                          );
                          return !!descriptions[id].length && (
                            <li key={`${item.id}-${id}`}>
                              <strong style={{ color: color.stock_descriptions.rgb }}>
                                {color.stock_descriptions.name}
                              </strong>
                              {' - '}
                              {descriptions[id].map(({ description, quantity: amount }, index) => (
                                <span className="cart-item-description" key={`${item.id}-${description.id}`}>
                                  {description.id === color.id ? amount : `${description.stock_descriptions.name}: ${amount}${index === descriptions[id].length - 1 ? '' : ', '}`}
                                </span>
                              ))}
                            </li>
                          );
                        })
                      ) }
                      {talles?.length > 0 && colors?.length === 0 && descriptions && (
                        Object.keys(descriptions).map((id) => {
                          const talle = talles.find(
                            ({ stock_description_id }) => stock_description_id === Number(id),
                          );
                          return !!descriptions[id].length && (
                            <li key={`${item.id}-${id}`}>
                              <strong style={{ color: talle.stock_descriptions.rgb }}>
                                {talle.stock_descriptions.name}
                              </strong>
                              {' - '}
                              {descriptions[id].map(({ description, quantity: amount }, index) => (
                                <span className="cart-item-description" key={`${item.id}-${description.id}`}>
                                  {description.id === talle.id ? amount : `${description.stock_descriptions.name}: ${amount}${index === descriptions[id].length - 1 ? '' : ', '}`}
                                </span>
                              ))}
                            </li>
                          );
                        })
                      )}
                      {!colors && !talles && (
                        <li>
                          <span className="summary-item-title">
                            {`Cantidad: ${quantity}`}
                          </span>
                        </li>
                      )}
                      <li>
                        <span className="summary-item-price">
                          {
                            renderPrice(item) ? (
                              <>
                                Precio por talle
                                {renderPriceDescriptions(talles)}
                              </>
                            ) : (
                              <>
                                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                                {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                              </>
                            )
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="separator" />
              </div>
            ))}
          </div>
          <ul className="summary-subtotal">
            <li>
              Subtotal:
              {discount === null
                ? (
                  <span className="float-right">
                    {`$${subtotalValue.toFixed(2)}`}
                  </span>
                )
                : (
                  <del className="float-right">
                    {`$${subtotalValue.toFixed(2)}`}
                  </del>
                )}
            </li>
            <li>
              Descuentos
              <span className="float-right">
                {
                  !coupon ? (
                    discount != null
                    && (
                      <>
                        {getLocalStorage('percentage')}
                        %
                      </>
                    )
                  ) : (
                    <>
                      {discount != null ? (`CUPÓN ${coupon.percentage ? (`${coupon.percentage}%`) : (`$${coupon.amount} y ${getLocalStorage('percentage')}%:`)} `) : (`CUPÓN ${coupon.percentage ? (`${coupon.percentage}%`) : (`$${coupon.amount}`)} `)}
                    </>
                  )
                }
              </span>
            </li>
            <li>
              Costo de envío:
              <span className="float-right">
                {shippingText}
              </span>
            </li>
            <div className="separator" />
            <li>
              Total:
              {
                discount === null
                  ? (
                    <span className="float-right">
                      {`$${totalValueWithoutDiscount.toFixed(2)}`}
                    </span>
                  )
                  : (
                    <span className="float-right">
                      {
                         coupon?.percentage ? (
                          `$${totalValueWithPercetage.toFixed(2)}`
                        ) : (
                          `$${totalValueWithDiscount.toFixed(2)}`
                        )
                      }
                    </span>
                  )
              }

            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

CartSummary.defaultProps = {
  coupon: null,
};

CartSummary.propTypes = {
  items: cartItemsArrayPropTypes.isRequired,
  shippingText: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
  }),
};

export default CartSummary;
