import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = () => (
  <footer className="footer" data-testid="footer">
    <div className="container">
      <div className="row footer-top">
        <div className="col-lg-5 col-md-12 footer-column">
          <span className="title">Cont&aacute;ctanos</span>
          <ul>
            <li>Laprida 482 San Isidro </li>
            <li>SAN ISIDRO - Argentina</li>
            <li>Tel&eacute;fono: +54 9 11 6432-5878</li>
            <li>
              <a href="mailto:ventas@sandyproietti.com">
                ventas@sandyproietti.com
              </a>
            </li>
            <li><Link to="/contacto">Ver mapa</Link></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-6 footer-column">
          <span className="title">Informaci&oacute;n</span>
          <ul>
            <li><Link to="/preguntas-frecuentes">Preguntas frecuentes</Link></li>
            <li><Link to="/contacto">Contacto</Link></li>
          </ul>
        </div>
        <div className="col-lg-5 col-md-6 footer-column social-links">
          <a href="https://www.facebook.com/share/QqUfL1D2PSaRfiUa/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook" />
          </a>
          <a href="https://www.instagram.com/sandyproietti?igsh=bjY3c3U2bmlyOXp4&utm_source=qr" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram" />
          </a>
        </div>
      </div>
      <div className="copyright-wrap d-md-flex py-4">
        <div className="copyright">
          &copy; 2024 Derechos reservados por
          {' '}
          <a href="https://info.jaypionba.com/ " target="_blank" rel="noopener noreferrer">Jaypion</a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
